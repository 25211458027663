import bed_img_1 from '../../assets/Images/Artboard 1@3x-100.webp'
import bed_img_2 from '../../assets/Images/Artboard 8 copy 2@3x-100.webp'
import bed_img_3 from '../../assets/Images/Artboard 8 copy 3@3x-100.webp'
import bed_img_4 from '../../assets/Images/Artboard 8 copy@3x-100.webp'
import bed_img_5 from '../../assets/Images/Artboard 8@3x-100.webp'
import bed_img_6 from '../../assets/Images/Artboard 17@3x-100.webp'
import bed_img_7 from '../../assets/Images/Artboard 18@3x-100.webp'
import bed_img_8 from '../../assets/Images/Artboard 22@3x-100.webp'
import bed_img_9 from '../../assets/Images/Artboard 23@3x-100.webp'
import bed_img_10 from '../../assets/Images/Artboard 24@3x-100.webp'
import bed_img_11 from '../../assets/Images/Artboard 26@3x-100.webp'
import bed_img_12 from '../../assets/Images/Artboard 27@3x-100.webp'
import bed_img_13 from '../../assets/Images/Artboard 28@3x-100.webp'
import bed_img_14 from '../../assets/Images/Artboard 29@3x-100.webp'
import bed_img_15 from '../../assets/Images/Artboard 30@3x-100.webp'
import bed_img_16 from '../../assets/Images/Artboard 31@3x-100.webp'
import bed_img_17 from '../../assets/Images/Artboard 32@3x-100.webp'
import bed_img_18 from '../../assets/Images/Artboard 33@3x-100.webp'
import bed_img_19 from '../../assets/Images/Artboard 34@3x-100.webp'

import drawer_1 from '../../assets/Images/Artboard 19 copy@3x-100.webp'

import mirror_drawer_1 from '../../assets/Images/Artboard 19@3x-100.webp'
import mirror_drawer_2 from '../../assets/Images/mirror_drawer_1.jpg'

import chair_1 from '../../assets/Images/Artboard 35@3x-100.webp'
import chair_2 from '../../assets/Images/Artboard 38@3x-100.webp'
import chair_3 from '../../assets/Images/Artboard 59@3x-100.webp'
import chair_4 from '../../assets/Images/Artboard 61@3x-100.webp'
import chair_5 from '../../assets/Images/Artboard 63@3x-100.webp'
import chair_6 from '../../assets/Images/Artboard 66@3x-100.webp'
import chair_7 from '../../assets/Images/Artboard 68@3x-100.webp'
import chair_8 from '../../assets/Images/Artboard 69@3x-100.webp'
import chair_9 from '../../assets/Images/Artboard 70@3x-100.webp'
import chair_10 from '../../assets/Images/Artboard 71@3x-100.webp'
import chair_11 from '../../assets/Images/Artboard 74@3x-100.webp'
import chair_12 from '../../assets/Images/Artboard 75@3x-100.webp'
import chair_13 from '../../assets/Images/Artboard 76@3x-100.webp'
import chair_14 from '../../assets/Images/Artboard 77@3x-100.webp'
import chair_15 from '../../assets/Images/Artboard 79@3x-100.webp'
import chair_16 from '../../assets/Images/Artboard 79_1@3x-100.webp'
import chair_17 from '../../assets/Images/Artboard 80@3x-100.webp'
import chair_18 from '../../assets/Images/Artboard 81@3x-100.webp'
import chair_19 from '../../assets/Images/Artboard 82@3x-100.webp'
import chair_20 from '../../assets/Images/Artboard 83@3x-100.webp'
import chair_21 from '../../assets/Images/Artboard 97@3x-100.webp'
import chair_22 from '../../assets/Images/Artboard 97_1@3x-100.webp'
import chair_23 from '../../assets/Images/Artboard 98@3x-100.webp'
import chair_24 from '../../assets/Images/Artboard 103@3x-100.webp'
import chair_25 from '../../assets/Images/Artboard 103_1@3x-100.webp'
import chair_26 from '../../assets/Images/chairs_1.jpg'

import center_table_1 from '../../assets/Images/Artboard 37@3x-100.webp'
import center_table_2 from '../../assets/Images/Artboard 40@3x-100.webp'
import center_table_3 from '../../assets/Images/Artboard 41@3x-100.webp'
import center_table_4 from '../../assets/Images/Artboard 42@3x-100.webp'
import center_table_5 from '../../assets/Images/Artboard 44@3x-100.webp'
import center_table_6 from '../../assets/Images/Artboard 45@3x-100.webp'
import center_table_7 from '../../assets/Images/Artboard 46@3x-100.webp'
import center_table_8 from '../../assets/Images/Artboard 47@3x-100.webp'
import center_table_9 from '../../assets/Images/Artboard 48@3x-100.webp'
import center_table_10 from '../../assets/Images/Artboard 49@3x-100.webp'
import center_table_11 from '../../assets/Images/Artboard 50@3x-100.webp'
import center_table_12 from '../../assets/Images/Artboard 51@3x-100.webp'
import center_table_13 from '../../assets/Images/Artboard 53@3x-100.webp'
import center_table_14 from '../../assets/Images/Artboard 54@3x-100.webp'
import center_table_15 from '../../assets/Images/Artboard 55@3x-100.webp'
import center_table_16 from '../../assets/Images/Artboard 56@3x-100.webp'
import center_table_17 from '../../assets/Images/Artboard 57@3x-100.webp'
import center_table_18 from '../../assets/Images/Artboard 58@3x-100.webp'
import center_table_19 from '../../assets/Images/Artboard 62@3x-100.webp'
import center_table_20 from '../../assets/Images/Artboard 64@3x-100.webp'
import center_table_21 from '../../assets/Images/Artboard 65@3x-100.webp'
import center_table_22 from '../../assets/Images/Artboard 67@3x-100.webp'
import center_table_23 from '../../assets/Images/Artboard 72@3x-100.webp'
import center_table_24 from '../../assets/Images/Artboard 73@3x-100.webp'
import center_table_25 from '../../assets/Images/Artboard 84@3x-100.webp'
import center_table_26 from '../../assets/Images/Artboard 85@3x-100.webp'
import center_table_27 from '../../assets/Images/Artboard 86@3x-100.webp'
import center_table_28 from '../../assets/Images/Artboard 87@3x-100.webp'
import center_table_29 from '../../assets/Images/Artboard 88@3x-100.webp'
import center_table_30 from '../../assets/Images/Artboard 89@3x-100.webp'
import center_table_31 from '../../assets/Images/Artboard 90@3x-100.webp'
import center_table_32 from '../../assets/Images/Artboard 91@3x-100.webp'
import center_table_33 from '../../assets/Images/Artboard 92@3x-100.webp'
import center_table_34 from '../../assets/Images/Artboard 93@3x-100.webp'
import center_table_35 from '../../assets/Images/Artboard 105@3x-100.webp'
import center_table_36 from '../../assets/Images/center_table_1.jpg'

import corner_stand_1 from '../../assets/Images/Artboard 94@3x-100.webp'
import corner_stand_2 from '../../assets/Images/Artboard 95@3x-100.webp'

import wooden_sofa_1 from '../../assets/Images/Artboard 99@3x-100.webp'
import wooden_sofa_2 from '../../assets/Images/Artboard 100@3x-100.webp'
import wooden_sofa_3 from '../../assets/Images/Artboard 102@3x-100.webp'
import wooden_sofa_4 from '../../assets/Images/Artboard 104@3x-100.webp'
import wooden_sofa_5 from '../../assets/Images/Artboard 106@3x-100.webp'
import wooden_sofa_6 from '../../assets/Images/Artboard 107@3x-100.webp'
import wooden_sofa_7 from '../../assets/Images/Artboard 108@3x-100.webp'
import wooden_sofa_8 from '../../assets/Images/Artboard 109@3x-100.webp'

import war_drobe_1 from '../../assets/Images/war_drope_1.jpg'

import sofa_1 from '../../assets/Images/sofa_1.jpg'
import sofa_2 from '../../assets/Images/sofa_2.jpg'
import sofa_3 from '../../assets/Images/sofa_3.jpg'
import sofa_4 from '../../assets/Images/sofa_4.jpg'
import sofa_5 from '../../assets/Images/sofa_5.jpg'
import sofa_6 from '../../assets/Images/sofa_6.jpg'

import recliner_1 from '../../assets/Images/recliners_1.jpg'
import recliner_2 from '../../assets/Images/recliners_2.jpg'

import pooja_room_1 from '../../assets/Images/pooja_room_1.jpg'
import pooja_room_2 from '../../assets/Images/pooja_room_2.webp'

import mattress_1 from '../../assets/Images/mattress_1.jpg'
import mattress_2 from '../../assets/Images/mattress_2.jpg'

import crockeries_1 from '../../assets/Images/crockeries_1.png'
import crockeries_2 from '../../assets/Images/crockeries_2.png'

import chimney_1 from '../../assets/Images/chimney_1.png'
import chimney_2 from '../../assets/Images/chimney_2.png'

import stove_1 from '../../assets/Images/stove_1.jpg'
import stove_2 from '../../assets/Images/stove_2.jpg'

import kitchen_appliances_1 from '../../assets/Images/appliances_1.jpg'
import kitchen_appliances_2 from '../../assets/Images/appliances_2.webp'

import home_ware_1 from '../../assets/Images/homewares_1.jpg'
import home_ware_2 from '../../assets/Images/homewares_2.png'


const Images = [
    {
        img : bed_img_1,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_2,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_3,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_4,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_5,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_6,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_7,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_8,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_9,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_10,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_11,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_12,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_13,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_14,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_15,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_16,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_17,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_18,
        type :"Bed room furnitures",
        subType: 'Cots'
    },
    {
        img : bed_img_19,
        type :"Bed room furnitures",
        subType: 'Cots'
    },

    {
        img : drawer_1,
        type :"Bed room furnitures",
        subType: 'Bedding essentials'
    },

    {
        img : mirror_drawer_1,
        type :"Bed room furnitures",
        subType: 'Bedding essentials'
    },
    {
        img : mirror_drawer_2,
        type :"Bed room furnitures",
        subType: 'Bedding essentials'
    },

    {
        img : chair_1,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_2,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_3,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_4,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_5,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_6,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_7,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_8,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_9,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_10,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_11,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_12,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_13,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_14,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_15,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_16,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_17,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_18,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_19,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_20,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_21,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_22,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_23,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_24,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_25,
        type :"Living room furnitures",
        subType: 'Chairs'
    },
    {
        img : chair_26,
        type :"Living room furnitures",
        subType: 'Chairs'
    },

    {
        img : center_table_1,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_2,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_3,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_4,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_5,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_6,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_7,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_8,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_9,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_10,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_11,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_12,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_13,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_14,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_15,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_16,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_17,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_18,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_19,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_20,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_21,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_22,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_23,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_24,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_25,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_26,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_27,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_28,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_29,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_30,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_31,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_32,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_33,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_34,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_35,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },
    {
        img : center_table_36,
        type :"Living room furnitures",
        subType: 'Centre tables'
    },

    {
        img : corner_stand_1,
        type :"Living room furnitures",
        subType: 'Corner stands'
    },
    {
        img : corner_stand_2,
        type :"Living room furnitures",
        subType: 'Corner stands'
    },

    {
        img : wooden_sofa_1,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_2,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_3,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_4,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_5,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_6,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_7,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },
    {
        img : wooden_sofa_8,
        type :"Living room furnitures",
        subType: 'Wooden sofas'
    },

    {
        img : war_drobe_1,
        type :"Bed room furnitures",
        subType: 'Wardrobes'
    },

    {
        img : sofa_1,
        type :"Living room furnitures",
        subType: 'Sofa'
    },
    {
        img : sofa_2,
        type :"Living room furnitures",
        subType: 'Sofa'
    },
    {
        img : sofa_3,
        type :"Living room furnitures",
        subType: 'Sofa'
    },
    {
        img : sofa_4,
        type :"Living room furnitures",
        subType: 'Sofa'
    },
    {
        img : sofa_5,
        type :"Living room furnitures",
        subType: 'Sofa'
    },
    {
        img : sofa_6,
        type :"Living room furnitures",
        subType: 'Sofa'
    },

    {
        img : recliner_1,
        type :"Living room furnitures",
        subType: 'Recliners'
    },
    {
        img : recliner_2,
        type :"Living room furnitures",
        subType: 'Recliners'
    },

    {
        img : pooja_room_1,
        type :"Living room furnitures",
        subType: 'Pooja rooms'
    },
    {
        img : pooja_room_2,
        type :"Living room furnitures",
        subType: 'Pooja rooms'
    },

    {
        img : mattress_1,
        type :"Bed room furnitures",
        subType: 'Mattress'
    },
    {
        img : mattress_2,
        type :"Bed room furnitures",
        subType: 'Mattress'
    },

    {
        img : crockeries_1,
        type :"Kitchen wares",
        subType: 'Crockeries'
    },
    {
        img : crockeries_2,
        type :"Kitchen wares",
        subType: 'Crockeries'
    },

    {
        img : chimney_1,
        type :"Kitchen wares",
        subType: 'Chimmneys'
    },
    {
        img : chimney_2,
        type :"Kitchen wares",
        subType: 'Chimmneys'
    },

    {
        img : stove_1,
        type :"Kitchen wares",
        subType: 'Stoves'
    },
    {
        img : stove_2,
        type :"Kitchen wares",
        subType: 'Stoves'
    },

    {
        img : kitchen_appliances_1,
        type :"Kitchen wares",
        subType: 'Kitchen appliances'
    },
    {
        img : kitchen_appliances_2,
        type :"Kitchen wares",
        subType: 'Kitchen appliances'
    },

    {
        img : home_ware_1,
        type :"Kitchen wares",
        subType: 'Home ware'
    },
    {
        img : home_ware_2,
        type :"Kitchen wares",
        subType: 'Home ware'
    },
]

export default Images