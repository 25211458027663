import React, { useState } from 'react'
import './Ourservices.css'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import HouseSidingOutlinedIcon from '@mui/icons-material/HouseSidingOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import livingRoom from '../../assets/Icons/living.png'
import bedRoom from '../../assets/Icons/bedroom.png'
import kitchen from '../../assets/Icons/kitchen.png'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';

const Ourservices = () => {

    const livingRoomfurnitures = ['Sofa','Wooden sofas','Centre tables','Recliners', 'Corner stands', 'Chairs', 'Pooja rooms']
    const bedRoomfurnitures = ['Mattress','Cots','Bedding essentials', 'Wardrobes']
    const kitchenWares = ['Crockeries', 'Chimmneys', 'Stoves', 'Kitchen appliances', 'Home ware']
    const trans1 = {duration:0.3}
    const trans2 = {duration:0.6}
    const trans3 = {duration:0.9}
    const navigate = useNavigate()


  return (
    <div className='container-fluid px-4 my-5'>
        <div className='h2 text-center fw-light'>
            <motion.div
            initial={{transform: 'translateY(5rem)'}}
            whileInView={{transform: 'translateY(0rem)'}}
            transition={trans2}
            viewport={{once:true}}
            >
                <span>Our Products</span>
            </motion.div>
        </div>
        <div className='ourservices d-grid container py-5'>
            <div className='row row-gap-5'>
                {/* <div className='d-flex flex-column align-items-center col-lg-4 col-sm-6'>
                    <FoundationOutlinedIcon className='fs-1'/>
                    <span className='py-4 h4'>Architecture</span>
                    <span className='lh-lg text-secondary'>We believe God is in the details . We work hard to give a character to the space by providing decor items, furniture, flooring, wall painting and other decorative elements.</span>
                </div> */}
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem",opacity:'0'}}
                    whileInView={{translateY:"0rem",opacity:'1'}}
                    viewport={{once:true}}
                    // whileHover={{ scale: 1.1 }}
                    transition={trans1}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center justify-content-start'>
                            {/* <HouseSidingOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/> */}
                            <img src={livingRoom} className='img-fluid w-25'/>
                            <span 
                            className='ourservices-title py-4 h4 fw-semibold text-center'
                            onClick={()=>navigate(
                                '/portfolio',
                                {state:{
                                    type: "Living room furnitures",
                                    subType: null
                                }}
                            )}
                            >
                                Living room <br/> furnitures
                            </span>
                            <span className='ourservices-content lh-lg'>
                                <ul>
                                    {
                                        livingRoomfurnitures?.map((item)=>(
                                            <li
                                            onClick={()=>navigate(
                                                '/portfolio',
                                                {state:{
                                                    type: "Living room furnitures",
                                                    subType: item
                                                }}
                                            )}
                                            >
                                                {item}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </span>
                        </div>
                    </motion.div>
                </div>
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem"}}
                    whileInView={{translateY:"0rem"}}
                    viewport={{once:true}}
                    transition={trans2}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center justify-content-start'>
                            {/* <MeetingRoomOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/> */}
                            <img src={bedRoom} className='img-fluid w-25'/>
                            <span 
                            className='ourservices-title py-4 h4 fw-semibold text-center'
                            onClick={()=>navigate(
                                '/portfolio',
                                {state:{
                                    type: "Bed room furnitures",
                                    subType: null
                                }}
                            )}
                            >
                                Bed room <br/> furnitures
                            </span>
                            <span className='ourservices-content lh-lg'>
                                <ul>
                                    {
                                        bedRoomfurnitures?.map((item)=>(
                                            <li
                                            onClick={()=>navigate(
                                                '/portfolio',
                                                {state:{
                                                    type: "Bed room furnitures",
                                                    subType: item
                                                }}
                                            )}
                                            >
                                                {item}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </span>
                        </div>
                    </motion.div>
                </div>
                <div className='col-lg-4 col-sm-6'>
                    <motion.div
                    initial={{translateY:"10rem"}}
                    whileInView={{translateY:"0rem"}}
                    viewport={{once:true}}
                    // whileHover={{ scale: 1.1 }}
                    transition={trans3}
                    >
                        <div className='ourservices-div d-flex flex-column align-items-center justify-content-start'>
                            {/* <LandscapeOutlinedIcon className='ourservices-icon' style={{fontSize:"60px"}}/> */}
                            <img src={kitchen} className='img-fluid w-25'/>
                            <span 
                            className='ourservices-title py-4 h4 fw-semibold text-center'
                            onClick={()=>navigate(
                                '/portfolio',
                                {state:{
                                    type: "Kitchen wares",
                                    subType: null
                                }}
                            )}
                            >
                                Kitchen wares
                            </span>
                            <span className='ourservices-content lh-lg'>
                                <ul>
                                    {
                                        kitchenWares?.map((item)=>(
                                            <li
                                            onClick={()=>navigate(
                                                '/portfolio',
                                                {state:{
                                                    type: "Kitchen wares",
                                                    subType: item
                                                }}
                                            )}
                                            >
                                                {item}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </span>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ourservices