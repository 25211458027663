import React, { useEffect } from 'react'
import './Home.css'
import Banner from '../src/Component/Banner/Banner'
import Footer from '../src/Component/Footer/Footer'
import About from '../src/Component/About/Aboutus'
import Service from './Component/Service/Service'
import Contact from './Component/Contact/Contact'
import Ourservices from './Component/Our_services/Ourservices'
import Clientreview from './Component/ClientReview/Clientreview'
import Googlemap from './Component/GoogleMap/Googlemap'
import Clientele from './Component/Clientele/Clientele'
import Video from './Component/Video/Video'
import Header from './Component/Header/Header'
import logo_2 from './assets/Logo/White logo - no background.png'
import menu_2 from './assets/Icons/menu_white.png'
// import Identity from './Component/Identity/Identity'

function Home() {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className=''>
      <Header 
      color={'white'} 
      icon={logo_2}
      mobile_icon={menu_2}
      />
      <Banner />
      <Video/>
      {/* <About /> */}
      <Service />
      <Ourservices/>
      {/* <Clientele/> */}
      <Clientreview/>
      <Contact />
      <Footer />
      <Googlemap/>
    </div>
    
  )
}
export default Home;