import React, { useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import Identity from '../Identity/Identity'
import Footer from '../Footer/Footer'
import Googlemap from '../GoogleMap/Googlemap'

const AboutPage = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className=''>
        <div className='pb-5'>
            <Navbar/>
        </div>
        <Identity/>
        <Footer/>
        <Googlemap/>
    </div>
  )
}

export default AboutPage