import React, { useEffect, useState } from 'react'
import './Portfolio.css'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useLocation } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Googlemap from '../GoogleMap/Googlemap'
import Images from '../Images/Images'

function Portfolio() {

    const location = useLocation()

    const options = ["All","Living room furnitures","Bed room furnitures","Kitchen wares"]

    const sub_options = {
        "Living room furnitures":["All","Sofa","Wooden sofas","Centre tables","Recliners","Corner stands","Chairs","Pooja rooms"],
        "Bed room furnitures":["All","Mattress","Cots","Bedding essentials","Wardrobes"],
        "Kitchen wares":["All","Crockeries","Chimmneys","Stoves","Kitchen appliances","Home ware"]
    }

    const [type,isType] = useState("All")
    const [subType, isSubType] = useState('All')
    const [selected,isSelected] = useState(Images)

    useEffect(()=>{
        window.scrollTo(0,0)
    })

    useEffect(()=>{
        if(location?.state?.type){
            isType(location?.state?.type)
            handleFilter(location?.state?.type)
        }
        if(location?.state?.subType){
            isSubType(location?.state?.subType)
            handleSubFilter(location?.state?.subType)
        }
    },[])

    const handleFilter = (option) =>{
        isType(option)
        isSubType("All")
        if(option !== "All"){
            isSelected(Images.filter(item => item?.type === option))
        }
        else{
            isSelected(Images)
        }
    }
    const handleSubFilter = (option) =>{
        isSubType(option)
        if(option !== "All"){
            isSelected(Images?.filter(item => item?.subType === option))
        }
        else{
            isSelected(Images.filter(item => item?.type === type))
        }
    }

  return (
    <div className='' style={{minHeight:"100vh"}}> {/*bg-black text-white */}
        <Navbar/>
        <div className='container' style={{paddingTop:'6rem',paddingBottom:'6rem'}}>
            <span className='h1 fw-light'>Products</span>
            <div 
            className='w-100 text-center pt-5'
            >
                {
                    options?.map((option,i)=>(
                        <span 
                        href='' 
                        className='pe-3 fw-light' 
                        key={i} 
                        style={{
                            color:option === type? "orangered":"black",
                            cursor:"pointer",
                            display: 'inline-block'
                        }}  
                        onClick={() => handleFilter(option)}
                        >
                            {option}
                        </span>
                    ))
                }
            </div>
            <div 
            className='w-100 text-center pt-3 pb-5'
            >
                {
                    sub_options[type]?.map((option,i)=>(
                        <span 
                        href='' 
                        className='pe-4 fw-light' 
                        key={i} 
                        style={{
                            color:option === subType? "orangered":"black",
                            cursor:"pointer",
                            display: 'inline-block'
                        }}  
                        onClick={() => handleSubFilter(option)}
                        >
                            {option}
                        </span>
                    ))
                }
            </div>
            <div>
                <ResponsiveMasonry columnsCountBreakPoints={{350:1, 750:2, 900:3}}>
                    <Masonry 
                    gutter='15px'
                    autoArrange = {true}
                    >
                        {
                            selected.map((item,i)=>{
                                return(
                                    <img 
                                    className='portfolio-img border border-1' 
                                    src={item.img} key={i} 
                                    style={{width:"100%"}} 
                                    alt=""
                                    />
                                )
                            })
                        } 
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
        <Footer/>
        <Googlemap/>
    </div>
  )
}

export default Portfolio