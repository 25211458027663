import React from 'react';
import './Clientreview.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner_img from '../../assets/Videos/SodaPDF-converted-10x15.png';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { motion } from 'framer-motion'

const Clienreview = () => {

    const clientReviews = [
        {
            clientName : 'Hitesh Parmar',
            clientReview : 'Purchased office furniture and home furnishings for our company since many years. Best experience and service is very good.'
        },
        {
            clientName : 'Keerthana G',
            clientReview : 'Such a good and affordable store .You can see lots of new collections here . Workers were friendly and quality was amazing 😇'
        },
        {
            clientName : 'jilani Basha',
            clientReview : 'Excellent product quality & furniture variety. One will find everything here for house furnishings & decoration.'
        },
        {
            clientName : 'anilkumar kumar',
            clientReview : "It's nice place to buy quality furnitures. Cushy furniture mall Kuppam would recommend people can go there choose good furniture. Owner is good. We can buy with reasonable price."
        },
        {
            clientName : 'Ashok Gayathri',
            clientReview : "Best quality and valuable prices. There are good furniture and the products are very satisfying. Friendly staff"
        },
    ]

    const trans1 = {duration: 0.6}

  return (
    <div className="container clientreviews">
        <div className='d-lg-grid'>
            <div className='row row-gap-4'>
                <div className="client_review_img col-lg-6 col-md-12 d-flex justify-content-center">
                    <img src={banner_img} alt="" className='img-fluid'/>
                </div>
                <div className="clientreviews-carousel col-lg-6 col-md-12 align-items-center text-center">
                <motion.div
                initial={{transform:'translateY(5rem)',opacity:'0'}}
                whileInView={{transform:'translateY(0rem)',opacity:'1'}}
                transition={trans1}
                viewport={{once:true}}
                >
                    <span className='clientreviews-carousel-title fs-3'>What Our Clients say about us</span>
                </motion.div>
                    <Carousel
                    className='carousel position-relative'
                        showThumbs={false} 
                        showStatus={false}
                        infiniteLoop={true}
                        centerMode={false}
                        showArrows={true} 
                        autoPlay={true}
                        showIndicators={false}
                        interval={5000}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        dynamicHeight={true}
                          renderArrowNext={(clickHandler) => (
                              <div
                                className='position-absolute top-0 bottom-0 end-0 d-flex justify-content-center align-items-center'
                                onClick={clickHandler}
                              >
                                <FaCaretRight className="fs-5" />
                              </div>
                            )}
                          renderArrowPrev={(clickHandler) => (
                              <div
                                className='position-absolute start-0 bottom-0 top-0 d-flex align-items-center justify-content-center z-1'
                                // style={{zIndex:'1'}}
                                onClick={clickHandler}
                              >
                                <FaCaretLeft className="fs-5" />
                              </div>
                            )}
                    >
                        {
                            clientReviews.map((review,index)=>(
                                <div className='client_carousel px-4' key={index}>
                                    <div className='carousel-slide-text-container d-flex flex-column text-wrap'>
                                        <em className='py-4 client-review'>{review.clientReview}</em>
                                        <div className='d-flex flex-column'>
                                            <span className='fw-bold'>{review.clientName}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>

    </div>
    
    
  );
};

export default Clienreview;