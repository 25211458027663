import React, { useEffect, useRef, useState } from 'react'
import myVideo from '../../assets/Videos/cushyfurnituremall.com.mp4'
import {PlayCircleTwoTone} from '@mui/icons-material';

const Video = () => {
  const [play, setPlay] = useState(false)
  const videoRef = useRef(null)

  return (
    <div className='py-5' style={{position: 'relative'}}>
      {/* {!play?
        <PlayCircleTwoTone
        className='text-primary d-none d-md-flex'
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          margin: 'auto',
          fontSize: '60px'
        }}
        />:null
      } */}
      <a href='#getInTouch'>
      <video 
      src={myVideo}
      className='w-100 h-100'  
      style={{objectFit: 'cover'}} 
      autoPlay
      muted
      // poster={require('../../assets/Videos/SodaPDF-converted-10x15.png')}
      // onPlaying={()=>setPlay(true)}
      // onPause={()=>setPlay(false)}
      ref={videoRef} 
      playsInline
      >
      </video>
      </a>
    </div>
  )
}

export default Video