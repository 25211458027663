import React, { useState } from 'react';
import './Contact.css';
import { Container, Row, Col } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [Pnumber, setPnumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(null);
  // const [subjectError, setSubjectError] = useState(null);
  const [msgError, setMsgError] = useState(null);
  const [loading, setLoading] = useState(false)

  const templateparam = {
    name : name,
    phoneNumber : Pnumber,
    email : email,
    message : message
}

  const validation = () => {
    setLoading(true)
    emailjs.send('service_867odkh','template_afzobxp', templateparam,{
      publicKey: 'APmWuZY4Pqq_MnZLt'
    })
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
       setEmail('')
       setName('')
      //  setSubject('')
       setPnumber('')
       setMessage('')
       setLoading(false)
    }, function(err) {
       console.log('FAILED...', err);
       setLoading(false)
    });
    // const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // if (regex.test(email)) {
    //   setEmail(email);
    //   setError(null);
    // } else {
    //   setError("Invalid Email");
    // }
    // if (name.length > 0) {
    //   setName(name);
    //   setNameError(null);
    // } else {
    //   setNameError('please Enter a name');
    // }
    // if (subject.length > 0) {
    //   setSubject(subject);
    //   setSubjectError(null);
    // } else {
    //   setSubjectError('please Enter a subject');
    // }
    // if (message.length > 0) {
    //   setMessage(message);
    //   setMsgError(null);
    // } else {
    //   setMsgError('please Enter a Message');
    // }
  };

  return (
    <div className='contact_us mt-5' id='getInTouch'>
      {/* <h2>Contact Us</h2> */}
      <div id="contact" className="contact-area section-padding">
        <Container>
          <div className="section-title text-center fw-light">
            <h1 className='fw-light'>Get in Touch</h1>
            <p>
              Let us make your space speaks.
            </p>
          </div>
          <Row>
            <Col lg={12}>
              <div className="contact">
                <form
                  className="form"
                  name="enq"
                  method="post"
                  action="contact.php"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation();
                  }}
                >
                  <Row>
                    <Col md={6}>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control mt-2 mb-2"
                        placeholder="Name"
                        required
                      />
                      {nameError && (
                        <div className="error-message" style={{ color: 'red' }}>
                          {nameError}
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control mt-2 mb-2"
                        placeholder="Email"
                        required
                      />
                      {error && (
                        <div className="error-message" style={{ color: 'red' }}>
                          {error}
                        </div>
                      )}
                    </Col>
                    {/* <Col md={6}>
                      <input
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="form-control mt-2 mb-2"
                        placeholder="Subject"
                        required
                      />
                      {subjectError && (
                        <div className="error-message" style={{ color: 'red' }}>
                          {subjectError}
                        </div>
                      )}
                    </Col> */}
                    <Col md={12}>
                      <input
                        type="text"
                        name="PhoneNumber"
                        value={Pnumber}
                        onChange={(e) => setPnumber(e.target.value)}
                        className="form-control mt-2 mb-2"
                        placeholder="Phone Number"
                        required
                      />
                      {/* {subjectError && (
                        <div className="error-message" style={{ color: 'red' }}>
                          {subjectError}
                        </div>
                      )} */}
                    </Col>
                    <Col md={12}>
                      <textarea
                        rows="6"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="form-control mt-2 mb-2"
                        placeholder="Your Message"
                      />
                      {msgError && (
                        <div className="error-message" style={{ color: 'red' }}>
                          {msgError}
                        </div>
                      )}
                    </Col>
                    <Col md={12} className="text-center">
                      <button
                        type="submit"
                        value="Send message"
                        name="submit"
                        className="btn btn-contact-bg btn-primary mt-3"
                        title="Submit Your Message!"
                      >
                        {
                          loading?
                          <span className='spinner-border spinner-border-sm text-light'></span>:
                          <span>Send Message</span>
                        }
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
