import React from 'react'
import './Identity.css'

const Identity = () => {
  return (
    <>
    <div className='identity-container d-flex flex-column text-center container py-5'>
        <div className='identity-first-div d-flex flex-column lh-lg'>
            <i className='identity-title'>Who <span className='fw-medium fst-normal'>We Are</span></i>
            <span className='identify-content'>
                We are South India’s leading modular furniture manufacturer. With the passage of time and with curiosity and enthusiasm, we have established ourselves as the most dependable modular furniture manufacturers among our clients.
            </span>
            <span className='identify-content'>
                We offer an uncountable variety of modest designs and personalised customisation. We value the customers’ preferences and make no compromises on the authenticity of products and their sustainability.
            </span>
            <span className='identify-content'>
                We value our clients who stood with us in our journey and place a high priority on their trust.
            </span>
        </div>
        <div className='identity-second-div d-flex flex-column lh-lg'>
            <i className='identity-title'>Why <span className='fw-medium fst-normal'>Cushy</span></i>
            <span className='identify-content'>
            With expertised crafting and unwavering policies of quality. HOMPAC is in a 
            journey to propel par excellence in the field of modular furniture manufacturing.
            Over the years, we have excelled as the customer’s No. 1 choice for quality and 
            sustainability: HOMPAC draws a new definition of qualitative production and 
            distribution with modest technologies & fine-crafting techniques. A well-organised
            team and a plan of action make our brand name more relevant each day.
            </span>
            <span className='identify-content'>
            Hompac values your money and delivers the most worthwhile service. As the 
            customer stands as the first priority, we always strive to exceed the customer’s 
            level of expectation and is on a mission to make your life more modest & and 
            comfortable without compromising on the quality & sustainability of the product.
            </span>
        </div>
    </div>
    </>
  )
}

export default Identity