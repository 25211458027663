import './App.css';
import Home from './Home';
import Portfolio from './Component/Portfolio/portfolio';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AboutPage from './Component/AboutPage/AboutPage';

function App() {
  const navigate = useNavigate()
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/about' element={<AboutPage/>}/>
      </Routes>
    </div>
  );
}

export default App;


