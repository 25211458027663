import React from 'react'
import Navbar from '../Navbar/Navbar';

const Header = ({color,icon,mobile_icon}) => {
  return (
    <div>
      <Navbar 
      color={color}
      icon={icon}
      mobile_icon={mobile_icon}
      />
    </div>
  )
}

export default Header;

