import React from 'react'
// import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
    <div style={{
      position: 'relative', color: 'white', background: 'red',
      height: 40, width: 60, top: -20, left: -30,
    }}>
      {text}
    </div>
  );

function Googlemap() {

    // const defaultProps = {
    //     center: {
    //       lat: 10.99835602,
    //       lng: 77.01502627
    //     },
    //     zoom: 11
    //   };

  return (
    <div>
        <div className=''>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15565.689250013333!2d78.3402343!3d12.7510636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3badb96d5d1f0af3%3A0xd9bb7f7f8500a37f!2sCushy%20Furniture%20Mall!5e0!3m2!1sen!2sin!4v1709548967052!5m2!1sen!2sin" 
          style={{border:0,width:"100%",height:"50vh"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
            {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
                />
            </GoogleMapReact> */}
        </div>
        <div className='container d-flex flex-sm-row flex-column align-items-center justify-content-sm-between text-secondary py-5 font-monospace'>
            <span className='pb-sm-0 pb-3'>© 2020 Copyright: Cushy Furniture</span>
            <div className='d-flex flex-column gap-1'>
              <span>Website by Best Labz</span>
              <span>+91 77085 45098</span>
            </div>
        </div>
    </div>
  )
}

export default Googlemap