import React, { useEffect, useState } from 'react';
import './Banner.css';
import carousel_img1 from '../../assets/Images/Artboard 22@3x-100.webp'
import carousel_img2 from '../../assets/Images/Artboard 18@3x-100.webp'
import carousel_img3 from '../../assets/Images/Artboard 102@3x-100.webp'
import logo from '../../assets/Logo/White logo - no background.png'
import { Carousel } from 'react-responsive-carousel';


const Banner = () => {

  const [currentSlide, setCurrentSlide] = useState(0)

  const contents = [
    {
      img : carousel_img1,
    },
    {
      img : carousel_img2,
    },
    {
      img : carousel_img3,
    }
  ]

  return (
    <div>
      <div
      className='banner-logo w-100 h-100 d-flex justify-content-center align-items-center'
      style={{
        position: 'absolute',
        zIndex: '1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'

      }}
      >
        <img src={logo} alt="logo" className='logo img-fluid'/>
      </div>
      <div className=''>
        <Carousel
          onChange={(index) => setCurrentSlide(index)}
          showThumbs={false} 
          showStatus={false}
          infiniteLoop={true} 
          centerMode={false}
          showArrows={false} 
          showIndicators={false}
          autoPlay={true}
          interval={5000}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50} 

        >
          {contents?.map((content, index) => (
            <div key={index} className="position-relative bg-black">
              <img src={content?.img} alt='' className='opacity-50' style={{height: '100vh',objectFit: 'cover'}}/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
